import React from 'react';
import {Container} from 'react-bootstrap';
import Iframe from 'react-iframe'


const DataForm = () => {

    return (<Container className={"mt-3 no-pd"}>
            {
                <div className={"form-size"}>
                    <Iframe
                        className="responsive-iframe"
                        url={"https://sosepe.com/attivazione-miopediatra/"}
                        display="block"
                        position="relative"
                        id="iframe_id"
                    />
                </div>
            }
        </Container>
    );
}

export default DataForm;